import { css } from "@emotion/react";

const Globalstyle = css`
    :root {
        --vh: 100%;
    }
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body {
        font-family: "Pretendard Variable", Pretendard, -apple-system,
            BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
            "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        line-height: 1.5;
        margin: 0;
        padding: 0;
    }
`;

export default Globalstyle;
