import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import ContactBackgroundCircle from "../component/Contact/ContactBackgroundCircle";
import ContactForm from "../component/Contact/ContactForm";
import SnsLink from "../component/Contact/SnsLink";

export default function ContactContainer() {
    return (
        <Box position="relative" width="100vw" height="100vh">
            <PortalLink name="CONTACT"></PortalLink>
            <Subject>
                <LogoImg
                    alt=""
                    src={process.env.PUBLIC_URL + `/img/logo_s.png`}
                />
                <h2>CONTACT</h2>
                <div>
                    디지트에서 함께 길을 걸어갈 파트너들을 상시 모집합니다.
                </div>
                <SnsLink />
            </Subject>
            <ContactForm />
            <ContactBackgroundCircle />
        </Box>
    );
}

const LogoImg = styled.img`
    width: 30px;
    height: 30px;
    object-fit: contain;
    position: absolute;
    top: -10px;
    right: -40px;
    @media (max-width: 600px) {
        transform: translateX(30px);
    }
`;

const PortalLink = styled.a`
    opacity: 0;
`;

const Subject = styled.div`
    position: absolute;
    top: 180px;
    left: 150px;

    div {
        margin-left: 5px;
    }
    h2 {
        font-size: 80px;
        font-weight: 600;
        line-height: 80px;
        margin-bottom: 0;
        margin-top: 0;
    }

    @media (max-width: 1400px) {
        top: 50px;
    }

    @media (max-width: 850px) {
        top: 50px;
        left: 30px;

        h2 {
            font-size: 60px;
            line-height: 60px;
        }
        img {
            right: 50px;
        }
    }
    @media (max-width: 600px) {
        top: -10px;

        div {
            width: 320px;
        }
    }
    @media (max-width: 380px) {
        top: -40px;
    }
`;
