import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";

import PeopleCarousel from "../component/People/PeopleCarousel";

export default function PeopleContainer() {
    return (
        <Box position="relative" width="100vw" height="100vh">
            <PortalLink name="PEOPLE"></PortalLink>
            <Subject>
                <div>디지트의 사람들</div>
                <img alt="" src={process.env.PUBLIC_URL + `/img/logo_s.png`} />
                <h2>PEOPLE</h2>
            </Subject>
            <PeopleCarousel />
        </Box>
    );
}

const PortalLink = styled.a`
    opacity: 0;
`;

const Subject = styled.div`
    position: absolute;
    top: 200px;
    left: 150px;
    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        position: absolute;
        top: 10px;
        right: -40px;
    }
    div {
        margin-left: 5px;
    }
    h2 {
        font-size: 80px;
        font-weight: 600;
        line-height: 80px;
        margin-bottom: 0;
        margin-top: 0;
    }
    @media (max-width: 1200px) {
        left: 90px;
    }

    @media (max-width: 950px) {
        left: 50px;
    }

    @media (max-width: 850px) {
        left: 30px;

        h2 {
            font-size: 60px;
            line-height: 60px;
        }
    }
`;
