import React from "react";
import styled from "@emotion/styled";

const TextWrapper = styled.div`
    margin-right: 100px;
    a {
        text-decoration: none;
    }

    div {
        cursor: pointer;
        user-select: none;
        font-weight: 500;
        font-size: 16px;
    }
    div:hover {
        color: #0d5bd9;
        transition: 0.1s;
    }
    @media (max-width: 900px) {
        margin: 0;
    }
`;

export default function Menu({ text, onClick }) {
    return (
        <TextWrapper onClick={onClick}>
            <div>
                <a href={`#${text}`}>{text}</a>
            </div>
        </TextWrapper>
    );
}
