import styled from "@emotion/styled";
import React from "react";

export default function ClientItem({ url }) {
    return (
        <div>
            <ClientWrapper>
                <img
                    alt=""
                    src={process.env.PUBLIC_URL + `/img/clients/${url}.png`}
                />
            </ClientWrapper>
        </div>
    );
}

const ClientWrapper = styled.li`
    list-style-type: none;

    max-width: 260px;
    height: 75px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
