import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

export default function ProjectItem({ src }) {
    const [randomY, setRandomY] = useState();
    useEffect(() => {
        setRandomY(Math.floor(Math.random() * 100));
    }, []);
    return (
        <>
            <Item style={{ transform: `translateY(${randomY}px)` }}>
                <img src={src} alt="1"></img>
            </Item>
        </>
    );
}

const Item = styled.div`
    overflow: hidden;
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 550px;
    }

    @media only screen and (max-width: 1100px) {
        width: 500px;
        height: 500px;
        img {
            width: 500px;
        }
    }
    @media only screen and (max-width: 930px) {
        width: 430px;
        height: 430px;
        img {
            width: 430px;
        }
    }
    @media only screen and (max-width: 850px) {
        width: 430px;
        height: 500px;
        img {
            width: 430px;
        }
    }
    @media only screen and (max-width: 600px) {
        width: 480px;
        height: 520px;
        img {
            width: 480px;
        }
    }
`;
