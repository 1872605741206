import React, { useState } from "react";
import styled from "@emotion/styled";
import ProjectItem from "./ProjectItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { allItemData, itemMap } from "../../constants/itemData";
import CategoryLi from "./CategoryLi";
import { useSetRecoilState } from "recoil";
import { categorySelcetState } from "../../atoms/atom";

export default function Carousel() {
    const [itemData, setItemData] = useState([...allItemData]);
    const setIsActive = useSetRecoilState(categorySelcetState);

    const onChangeItemData = (e) => {
        let items = itemMap[e.target.outerText];
        setIsActive(e.target.outerText);
        setItemData([...items]);
    };

    return (
        <>
            <ProjectsItem>
                <ProjectItemCategory>
                    <CategoryLi
                        text="ALL"
                        onChangeItemData={onChangeItemData}
                    />
                    <CategoryLi
                        text="VR DEVELOPMENT"
                        onChangeItemData={onChangeItemData}
                    />
                    <CategoryLi
                        text="WEB/APP SOLUTION"
                        onChangeItemData={onChangeItemData}
                    />
                    <CategoryLi
                        text="PARAMETRIC CONSULTING"
                        onChangeItemData={onChangeItemData}
                    />
                </ProjectItemCategory>
                <ProjectItemWrapper>
                    <ProjectItemSlider {...settings}>
                        {itemData.map((url) => {
                            return (
                                <ProjectItem
                                    key={url}
                                    src={
                                        process.env.PUBLIC_URL +
                                        `/img/projectimages/${url}.png`
                                    }
                                />
                            );
                        })}
                    </ProjectItemSlider>
                </ProjectItemWrapper>
            </ProjectsItem>
        </>
    );
}

const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    speed: 2000,
    appendDots: (dots) => (
        <div
            style={{
                width: "100%",
                position: "absolute",
                left: "-400px",
                bottom: "-100px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <ul> {dots} </ul>
        </div>
    ),
    dotsClass: "dots_custom",
    responsive: [
        {
            breakpoint: 1410,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ProjectItemWrapper = styled.div`
    width: 100%;
    margin-top: 50px;
`;

const ProjectItemSlider = styled(Slider)`
    width: 100%;

    .dots_custom {
        display: inline-block;
        vertical-align: middle;
        margin: auto 0;
        padding: 0;
    }

    .dots_custom li {
        list-style: none;
        cursor: pointer;
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    .dots_custom li button {
        border: none;
        background: #000;
        color: transparent;
        cursor: pointer;
        display: block;
        height: 6px;
        width: 20px;
        padding: 0;
    }

    .dots_custom li.slick-active button {
        background-color: #0d5bd9;
    }
`;

const ProjectsItem = styled.div`
    width: 70%;
    margin-top: 100px;
    @media (max-width: 850px) {
        width: 90%;
    }
`;

const ProjectItemCategory = styled.ul`
    position: absolute;
    left: 100px;
    bottom: 300px;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 120px;

    li {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        cursor: pointer;
    }

    li:hover {
        color: #0d5bd9;
    }

    @media (max-width: 850px) {
        bottom: 500px;
        left: 0;
    }
`;
