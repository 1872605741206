import { Global } from "@emotion/react";
import { useScroll } from "framer-motion";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "./component/Footer/Footer";

import Header from "./component/Header/Header";
import ClientsContainer from "./container/ClientsContainer";
import ContactContainer from "./container/ContactContainer";

import IntroContainer from "./container/IntroContainer";
import PeopleContainer from "./container/PeopleContainer";
import ProjectContainer from "./container/ProjectConatiner";
import Globalstyle from "./styles/GlobalStyle";

import { useRecoilState } from "recoil";
import { scrollDisplayState } from "./atoms/atom";

function App() {
    const [display, setDisplay] = useRecoilState(scrollDisplayState);
    function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    useEffect(() => {
        setScreenSize();
    });
    useEffect(() => {
        if (window.scrollY < 2300) {
            setDisplay(true);
        } else {
            setDisplay(false);
        }
        console.log(window.scrollY);
        console.log(display);
    }, []);
    return (
        <>
            <Helmet>
                <title>DIGIT</title>
                <link
                    rel="shortcut icon"
                    href={process.env.PUBLIC_URL + `/img/logo_s.png`}
                />
                <link
                    rel="stylesheet"
                    as="style"
                    crossorigin
                    href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
                <Global styles={Globalstyle} />
            </Helmet>
            <Header />
            <IntroContainer />
            <ProjectContainer />
            <ClientsContainer />
            <PeopleContainer />
            <ContactContainer />
            <Footer />
        </>
    );
}

export default App;
