import React from "react";
import styled from "@emotion/styled";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { VStack } from "@chakra-ui/react";
import PeopleImage from "./PeopleImage";

export default function PeopleCarousel() {
    return (
        <>
            <VStack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
            >
                <PeopleItemWrapper>
                    <PeopleItemSlider {...settings}>
                        <PeopleImage url={1} />
                        <PeopleImage url={2} />
                        <PeopleImage url={3} />
                        <PeopleImage url={4} />
                        <PeopleImage url={1} />
                        <PeopleImage url={2} />
                        <PeopleImage url={3} />
                        <PeopleImage url={4} />
                    </PeopleItemSlider>
                </PeopleItemWrapper>
                <DummyBox />
            </VStack>
        </>
    );
}

const settings = {
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    responsive: [
        {
            breakpoint: 1410,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 920,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
};

const PeopleItemWrapper = styled.div`
    width: 100%;
`;

const PeopleItemSlider = styled(Slider)`
    width: 85%;

    margin: 120px 100px 0px auto;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

const DummyBox = styled.div`
    width: 12%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    right: 0;

    @media (max-width: 600px) {
        height: 0px;
    }
`;
