import React from "react";
import styled from "@emotion/styled";

const VideoWrapper = styled.div`
    video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }
`;

export default function BackgorundVideo() {
    return (
        <VideoWrapper>
            <video
                typeof="video/mp4"
                autoPlay
                loop
                muted
                playsInline
                src={"https://d38wytuckl9jns.cloudfront.net/introvideo.mp4"}
            >
                BackgorundVideo
            </video>
        </VideoWrapper>
    );
}
