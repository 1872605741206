import React from "react";

import styled from "@emotion/styled";

const DigitLogo = styled.img`
    width: 100px;
    height: 30px;
    object-fit: contain;
    margin-right: 30px;
`;

export default function Logo() {
    return <DigitLogo src={process.env.PUBLIC_URL + "/img/logo_w.png"} />;
}
