import { motion } from "framer-motion";
import React from "react";
import styled from "@emotion/styled";

const MaskWrapper = styled(motion.div)`
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    scale: 1.2;
`;

const BlackMask = styled.div`
    z-index: 2;
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url(${process.env.PUBLIC_URL + "/img/blackMask.svg"});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`;

export default function Mask({ scale, left }) {
    return (
        <MaskWrapper style={{ scale, left }}>
            <BlackMask />
        </MaskWrapper>
    );
}
