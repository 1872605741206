import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Logo from "./Logo";
import Menu from "./Menu";

import { scrollDisplayState } from "../../atoms/atom";
import { useRecoilState } from "recoil";

export default function Header() {
    const [display, setDisplay] = useRecoilState(scrollDisplayState);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [openMenu, setOpenMenu] = useState(false);

    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", setWindowDimensions);
        return () => {
            window.removeEventListener("resize", setWindowDimensions);
        };
    }, []);

    return (
        <HeaderWrapper isBlack={display}>
            <Logo />
            {!display && <LogoText>DIGIT</LogoText>}
            <HeaderGap />
            <MenuWrapper>
                {windowWidth > 900 ? (
                    <>
                        <Menu text="DIGIT" onClick={() => setDisplay(true)} />
                        <Menu
                            text="PROJECTS"
                            onClick={() => setDisplay(false)}
                        />
                        <Menu
                            text="CLIENTS"
                            onClick={() => setDisplay(false)}
                        />
                        <Menu text="PEOPLE" onClick={() => setDisplay(false)} />
                        <Menu
                            text="CONTACT"
                            onClick={() => setDisplay(false)}
                        />
                    </>
                ) : (
                    <>
                        <HambergerBar
                            isBlack={display}
                            onClick={() => {
                                setOpenMenu((res) => !res);
                            }}
                        >
                            <HambergerBarMid isBlack={display} />
                        </HambergerBar>
                        {openMenu && (
                            <MenuBar isBlack={display}>
                                <Menu
                                    text="DIGIT"
                                    onClick={() => setDisplay(true)}
                                />
                                <Menu
                                    text="PROJECTS"
                                    onClick={() => setDisplay(false)}
                                />
                                <Menu
                                    text="CLIENTS"
                                    onClick={() => setDisplay(false)}
                                />
                                <Menu
                                    text="PEOPLE"
                                    onClick={() => setDisplay(false)}
                                />
                                <Menu
                                    text="CONTACT"
                                    onClick={() => setDisplay(false)}
                                />
                            </MenuBar>
                        )}
                    </>
                )}
            </MenuWrapper>
        </HeaderWrapper>
    );
}

const LogoText = styled.div`
    font-size: 20px;
    position: absolute;
    left: 70px;
`;

const MenuBar = styled.div`
    width: 150px;
    height: 200px;
    padding: 15px;
    background-color: ${(props) => (props.isBlack ? "#000" : "#fff")};

    border-radius: 5px;
    position: absolute;
    right: 15px;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const HambergerBar = styled.div`
    width: 23.5px;
    height: 20px;
    border-top: 4px solid ${(props) => (props.isBlack ? "#fff" : "#000")};
    border-bottom: 4px solid ${(props) => (props.isBlack ? "#fff" : "#000")};
    border-radius: 2px;
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const HambergerBarMid = styled.div`
    width: 23.5px;
    height: 4px;
    border-radius: 5px;
    background-color: ${(props) => (props.isBlack ? "#fff" : "#000")};
`;

const HeaderWrapper = styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    align-items: center;
    padding: 30px;
    background-color: ${(props) => (props.isBlack ? "#000" : "#fff")};
    color: ${(props) => (props.isBlack ? "#fff" : "#000")};
    transition: 0.3s;
`;

const HeaderGap = styled.div`
    width: 20%;
`;

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;
