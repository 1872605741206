import React from "react";
import styled from "@emotion/styled";

const IntroTextWrapper = styled.div`
    z-index: 3;
    color: #fff;
    font-weight: 700;
    font-size: 60px;
    position: absolute;
    top: 40%;
    left: 10%;
    @media (max-width: 850px) {
        font-size: 45px;
        left: 5%;
    }
    @media (max-width: 600px) {
        font-size: 35px;
        left: 3%;
    }
`;

const Text = styled.div``;

const RightText = styled.div`
    text-align: right;
`;

export default function IntroText() {
    return (
        <IntroTextWrapper>
            <Text>VR DEVELOPMENT</Text>
            <RightText>WEB/APP SOLUTION</RightText>
            <Text>PARAMETRIC CONSULTING</Text>
        </IntroTextWrapper>
    );
}
