import React from "react";
import { useRecoilValue } from "recoil";
import styled from "@emotion/styled";
import { categorySelcetState } from "../../atoms/atom";

export default function CategoryLi({ text, onChangeItemData }) {
    const categoryState = useRecoilValue(categorySelcetState);

    return (
        <>
            {categoryState === String(text) ? (
                <SelectedCategory onClick={onChangeItemData}>
                    <span>&#9654;</span>
                    {text}
                </SelectedCategory>
            ) : (
                <li onClick={onChangeItemData}>{text}</li>
            )}
        </>
    );
}

const SelectedCategory = styled.li`
    color: #0d5bd9;
    display: flex;
    span {
        display: block;
        font-size: 9px;
        line-height: 24px;
    }
`;
