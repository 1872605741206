import { Box } from "@chakra-ui/react";
import React from "react";

export default function PeopleImage({ url }) {
    return (
        <Box marginRight="30px">
            <img
                alt={`people${url}`}
                src={process.env.PUBLIC_URL + `/img/people/${url}.png`}
            />
        </Box>
    );
}
