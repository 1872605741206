export const allItemData = [
    "project-0",
    "project-1",
    "project-2",
    "project-3",
    "project-4",
    "project-5",
    "project-6",
    "project-7",
    "project-8",
    "project-9",
    "project-10",
    "project-11",
    "project-12",
    "project-13",
    "project-14",
    "project-15",
];

const vrItemData = [
    "project-0",
    "project-1",
    "project-2",
    "project-3",
    "project-4",
    "project-5",
    "project-6",
];

const webAppItemData = [
    "project-7",
    "project-8",
    "project-9",
    "project-10",
    "project-12",
    "project-13",
];

const parametricItemData = [
    "project-11",
    "project-14",
    "project-15",
    "project-11",
    "project-14",
    "project-15",
];

export const itemMap = {
    ALL: allItemData,
    "VR DEVELOPMENT": vrItemData,
    "WEB/APP SOLUTION": webAppItemData,
    "PARAMETRIC CONSULTING": parametricItemData,
};
