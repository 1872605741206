import React from "react";
import styled from "@emotion/styled";
import Carousel from "../component/Projects/Carousel";

export default function ProjectContainer() {
    return (
        <>
            <PortalLink name="PROJECTS"></PortalLink>
            <ProjectWrapper>
                <ProjectSubject>
                    <img
                        alt=""
                        src={process.env.PUBLIC_URL + `/img/logo_s.png`}
                    />
                    <h2>PROJECTS</h2>
                    <div>
                        길을 찾아내는 사람들, <br />
                        남다른 결과를 이끌어내는 크리에이티브.
                    </div>
                </ProjectSubject>

                <Carousel />
            </ProjectWrapper>
        </>
    );
}
const PortalLink = styled.a`
    opacity: 0;
`;

const ProjectWrapper = styled.div`
    position: relative;
    width: 100vw;

    display: flex;
    align-items: center;
    @media (max-width: 850px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 100px 20px 0px 20px;
    }
`;

const ProjectSubject = styled.div`
    position: relative;
    margin-left: 100px;
    margin-bottom: 350px;
    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        position: absolute;
        top: 0;
        right: -30px;
    }
    div {
        margin-left: 5px;
    }
    h2 {
        font-size: 80px;
        line-height: 80px;
        font-weight: 600;
        margin-bottom: 0;
    }

    @media (max-width: 850px) {
        margin: 0;

        h2 {
            font-size: 60px;
            line-height: 60px;
        }
    }
`;
