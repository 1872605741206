import { Checkbox } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { HStack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import emailjs from "@emailjs/browser";

import React, { useRef, useState } from "react";

export default function ContactForm() {
    const form = useRef();
    const [check, setCheck] = useState(false);
    const [errorMessage, setMessage] = useState();

    const onChangeCheck = () => {
        setCheck((res) => !res);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (!check) {
            setMessage("개인정보 수집 및 이용에 동의해주세요.");
            return;
        } else {
            setMessage("");
        }

        emailjs
            .sendForm(
                "service_mn4r37m",
                "template_d5ke6dk",
                form.current,
                "FMYPJookoaHmX-_xY"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    e.target[0].value = "";
                    e.target[1].value = "";
                    e.target[2].value = "";
                    e.target[3].value = "";
                    setMessage("전송이 완료되었습니다");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <HStack
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
        >
            <ContactFormWrapper ref={form} onSubmit={sendEmail}>
                <input name="companyName" placeholder="회사명"></input>
                <input name="userName" placeholder="담당자(성명)"></input>
                <input name="email" placeholder="연락처(이메일)"></input>
                <textarea name="message" placeholder="내용"></textarea>
                <Box width="100%" alignItems="initial">
                    <Checkbox
                        onChange={onChangeCheck}
                        size="sm"
                        marginTop="10px"
                    >
                        <BlueLink>개인정보 수집 및 이용</BlueLink>에 대한 안내에
                        동의합니다
                    </Checkbox>
                    {errorMessage && (
                        <CheckErrorMessage>{errorMessage}</CheckErrorMessage>
                    )}
                </Box>
                <button>문의하기</button>
            </ContactFormWrapper>
        </HStack>
    );
}

const CheckErrorMessage = styled.div`
    font-size: 12px;
    color: red;
`;

const ContactFormWrapper = styled.form`
    width: 800px;
    height: 542px;
    background-color: #fff;
    margin-left: 450px;
    margin-top: -150px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

    @media (max-width: 1400px) {
        width: 800px;
        margin: 0 auto;
        margin-top: -10px;
        height: 450px;
    }

    @media (max-width: 850px) {
        height: 400px;
        margin: 0 auto;
        margin-top: -50px;
    }
    @media (max-width: 600px) {
        width: 80%;
        height: 380px;
        font-size: 12px;
        padding: 25px;
    }

    input {
        border-bottom: 1px solid #7d7d7d;
        width: 100%;
        height: 40px;
        margin-top: 10px;
    }
    textarea {
        border-bottom: 1px solid #7d7d7d;
        width: 100%;
        height: 120px;
        margin-top: 10px;
        resize: none;
    }

    button {
        background-color: #0d5bd9;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: #fff;
        margin-top: 10px;
    }
`;

const BlueLink = styled.span`
    color: #0d5bd9;
`;
