import React from "react";
import styled from "@emotion/styled";

const Dummy = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #000;
`;

export default function IntroDummyGap() {
    return <Dummy></Dummy>;
}
