import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import {
    AnimatePresence,
    motion,
    useScroll,
    useTransform,
} from "framer-motion";
import IntroText from "../component/Intro/IntroText";
import Mask from "../component/Intro/Mask";
import BackgorundVideo from "../component/Intro/BackgorundVideo";
import IntroDummyGap from "../component/Intro/IntroDummyGap";

import { scrollDisplayState } from "../atoms/atom";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import useIntersectionObserverOn from "../hooks/useIntersectionObserverOn";
import { useRecoilState } from "recoil";

export default function IntroContainer() {
    const { scrollYProgress } = useScroll();
    const scale = useTransform(scrollYProgress, [0, 1], [1, 30]);
    const left = useTransform(scrollYProgress, [0, 1], [0, -5000]);

    const [display, setDisplay] = useRecoilState(scrollDisplayState);

    const lastRef = useRef(null);
    const reloadRef = useRef(null);

    useIntersectionObserver({
        root: null,
        target: lastRef,
        onIntersect: () => {
            setDisplay(false);
        },
    });

    useIntersectionObserverOn({
        root: null,
        target: reloadRef,
        onIntersect: () => {
            setDisplay(true);
        },
        isViewPort: display,
    });

    // const displayNoneIntro = () => {
    //     if (scale.get() < 11 && display === false) {
    //         setDisplay(true);
    //         setDisplay(true);
    //     }
    //     if (scale.get() > 11 && display === true) {
    //         setDisplay(false);
    //         setDisplay(false);
    //     }
    // };

    // useEffect(() => {
    //     scale.onChange(() => {
    //         displayNoneIntro();
    //     });
    // }, [scale.get()]);

    return (
        <>
            <PortalLink name="DIGIT"></PortalLink>
            <AnimatePresence>
                {display && (
                    <IntroWrapper
                        initial={{ opacity: 0.1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0.1 }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        <IntroText />
                        <Mask scale={scale} left={left} />
                        <BackgorundVideo></BackgorundVideo>
                    </IntroWrapper>
                )}
            </AnimatePresence>

            <GapBlack />
            <ReloadSection ref={reloadRef} />
            <IntroDummyGap />
            <LastSection ref={lastRef} />
        </>
    );
}

const ReloadSection = styled.div`
    width: 100%;
    height: 10px;
    background-color: #000;
`;

const LastSection = styled.div`
    width: 100%;
    height: 10px;
    background-color: #000;
`;
const PortalLink = styled.a`
    opacity: 0;
`;

const IntroWrapper = styled(motion.div)`
    position: fixed;
    top: 0%;
`;

const GapBlack = styled.div`
    width: 100%;
    height: 220vh;
    z-index: 20;
    background-color: #000000;
`;
