import styled from "@emotion/styled";
import React from "react";

export default function SnsLink() {
    return (
        <SnsWrapper>
            <Link href="https://lucidspace.xyz/" target="_blank">
                <img alt="" src={process.env.PUBLIC_URL + `/img/lucid.png`} />
            </Link>
            <Link href="https://cafe.naver.com/digitarchi" target="_blank">
                <img alt="" src={process.env.PUBLIC_URL + `/img/cafe.png`} />
            </Link>
            <Link href="https://www.instagram.com/digit.tv/" target="_blank">
                <img
                    alt=""
                    src={process.env.PUBLIC_URL + `/img/instagram.png`}
                />
            </Link>
            <Link href="https://www.youtube.com/@digit1212" target="_blank">
                <img alt="" src={process.env.PUBLIC_URL + `/img/you.png`} />
            </Link>
            <Link href="https://pacer.kr/" target="_blank">
                <img alt="" src={process.env.PUBLIC_URL + `/img/pacer.png`} />
            </Link>
        </SnsWrapper>
    );
}

const SnsWrapper = styled.div`
    position: absolute;

    top: 120px;
    left: 0;
    display: flex;
`;

const Link = styled.a`
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin-right: 10px;
`;
