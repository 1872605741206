import React from "react";
import styled from "@emotion/styled";
import { HStack } from "@chakra-ui/react";
import ClientItem from "../component/Client/ClientItem";
import { clientImageData } from "../constants/clientData";
import ClientBackgroundCircle from "../component/Client/ClientBackgroundCircle";

export default function ClientsContainer() {
    return (
        <ClientWrapper>
            <PortalLink name="CLIENTS"></PortalLink>
            <ClientsSubject>
                <img alt="" src={process.env.PUBLIC_URL + `/img/logo_s.png`} />
                <h2>CLIENTS</h2>
                <div>디지트와 함께 걸어온 기업들</div>
            </ClientsSubject>
            <HStack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
            >
                <ClientItemsWrapper>
                    {clientImageData.map((res) => {
                        return <ClientItem key={"client" + res} url={res} />;
                    })}
                </ClientItemsWrapper>
                <ClientBackgroundCircle />
            </HStack>
        </ClientWrapper>
    );
}

const ClientWrapper = styled.div`
    position: relative;
    width: 100vw;
    margin: 150px 0px;
    @media (max-width: 600px) {
        margin: 100px 0px 0px 0px;
    }
`;

const PortalLink = styled.a`
    opacity: 0;
`;

const ClientsSubject = styled.div`
    position: absolute;
    right: 200px;
    bottom: -50px;

    @media (max-width: 1200px) {
        right: 100px;
    }
    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        position: absolute;
        top: 0;
        right: -30px;
    }
    div {
        margin-left: 5px;
    }
    h2 {
        font-size: 80px;
        line-height: 80px;
        font-weight: 600;
        margin-bottom: 0;
    }
    @media (max-width: 850px) {
        right: 35px;
        bottom: -50px;
        h2 {
            font-size: 60px;
            line-height: 60px;
        }
    }
    @media (max-width: 380px) {
        bottom: -70px;
    }
`;

const ClientItemsWrapper = styled.ul`
    display: grid;
    width: 90%;
    grid-template-columns: repeat(auto-fit, minmax(280px, max-content));
    grid-gap: 16px;
    justify-content: center;
    padding: initial;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(230px, max-content));
        grid-gap: 8px;
    }
    @media (max-width: 800px) {
        grid-template-columns: repeat(auto-fit, minmax(180px, max-content));
        grid-gap: 5px;
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
        grid-gap: 3px;
    }
    @media (max-width: 510px) {
        grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
        grid-gap: 2px;
    }
`;
