import styled from "@emotion/styled";
import React from "react";

export default function ContactBackgroundCircle() {
    return (
        <Background>
            <img alt="" src={process.env.PUBLIC_URL + `/img/circleL.png`} />
        </Background>
    );
}

const Background = styled.div`
    position: absolute;
    top: 300px;
    right: 200px;
    z-index: -1;

    @media (max-width: 1200px) {
        right: -200px;
    }
`;
