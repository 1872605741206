import { HStack } from "@chakra-ui/react";

import styled from "@emotion/styled";
import React from "react";

export default function Footer() {
    return (
        <FooterContainer>
            <HStack>
                <img alt="" src={process.env.PUBLIC_URL + `/img/logo_s.png`} />
                <LogoSubject>DIGIT</LogoSubject>
            </HStack>
            <FooterContent>
                대표자 : 한기준
                <br />
                서울시 마포구 독막로2길 12, A동 2층 <br />
                사업자 등록번호 : 232-01-32125
                <br />
                <span>Lucidspace ⓒ 2022. all Rights Reserved.</span>
            </FooterContent>
        </FooterContainer>
    );
}

const FooterContainer = styled.footer`
    width: 100%;
    height: 230px;
    position: absolute;
    background-color: #f1f4f8;
    bottom: 0;
    padding: 20px 150px;
    @media (max-width: 850px) {
        padding: 20px 50px;
    }
    @media (max-width: 600px) {
        height: 150px;
        font-size: 12px;
    }
`;

const LogoSubject = styled.h2`
    font-size: 20px;
`;

const FooterContent = styled.div`
    font-size: 14px;
    margin-top: 15px;
    margin-left: 3px;
    span {
        display: block;
        margin-top: 20px;
        color: #b4b4b4;
    }
    @media (max-width: 600px) {
        font-size: 12px;
    }
`;
