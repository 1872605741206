import { useEffect } from 'react';

export default function useIntersectionObserver({
    root,
    target,
    onIntersect,
    threshold = 1.0,
    rootMargin = '30px',
    enabled = true,
}) {
    useEffect(() => {
        if (!enabled) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(
                    (entry) => entry.isIntersecting && onIntersect(),
                );
            },
            {
                root,
                rootMargin,
                threshold,
            },
        );

        const el = target && target.current;

        if (!el) {
            return;
        }

        observer.observe(el);

        return () => {
            observer.unobserve(el);
        };
    }, [target, enabled, root, threshold, rootMargin, onIntersect]);
}
