import styled from "@emotion/styled";
import React from "react";

export default function ClientBackgroundCircle() {
    return (
        <Background>
            <img alt="" src={process.env.PUBLIC_URL + `/img/circleM.png`} />
        </Background>
    );
}

const Background = styled.div`
    position: absolute;
    top: 100px;
    right: 150px;
    z-index: -1;

    @media (max-width: 1200px) {
        right: 50px;
    }
`;
