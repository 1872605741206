import { atom } from "recoil";

export const categorySelcetState = atom({
    key: "categorySelectState",
    default: "ALL",
});

export const scrollDisplayState = atom({
    key: "scrollDisplayState",
    default: true,
});
